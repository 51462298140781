/* toastify-custom.css */

.Toastify__toast {
    background-color: #ffffff !important;
        color: #333333 !important;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.Toastify__close-button {
    color: #666666 !important;
}

.Toastify__progress-bar {
    background-color: #9C27B0 !important;
}